import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!

  return (_openBlock(), _createBlock(_component_el_checkbox, {
    id: _ctx.id,
    size: _ctx.size,
    label: _ctx.label,
    modelValue: _ctx.inputModelValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputModelValue) = $event))
  }, null, 8, ["id", "size", "label", "modelValue"]))
}