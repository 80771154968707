
import {
  ref,
  onMounted,
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'base-phone-text',
  props: {
    modelValue: {
      type: String,
    },
  },
  setup(props, context) {
    const myInputModel = ref('');

    const formatPhoneNumber = str => {
      const cleaned = ('' + str).replace(/\D/g, '');
      const length = cleaned.length - 1;

      if (cleaned.length === 11 && cleaned.startsWith('7')) {
        const match = cleaned.match(/^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);
        if (match) {
          const intlCode = (match[1] ? '+7 ' : '');
          return [intlCode, '(', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('');
        }
      } else {
        const match2 = cleaned.match(/(\d{1})(\d{6,})$/);

        if (match2) {
          const intlCode = (match2[1] ? `+${match2[1]}`: '');
          return [intlCode, ' ', match2[2]].join('');
        }
      }

      return null;
    };

    /**
     * Mounted hook.
     */
    onMounted(() => {
      myInputModel.value = formatPhoneNumber(props.modelValue);
    });

    return {
      // maskedValue,
      myInputModel,
    };
  },
})
