import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createBlock(_component_el_input, {
    id: _ctx.id,
    type: _ctx.type,
    class: _normalizeClass(["my-input w-100", { 'el-input--invalid': !_ctx.isValid }]),
    maxlength: _ctx.maxLength,
    minlength: _ctx.minLength,
    clearable: _ctx.clearable,
    placeholder: _ctx.placeholder,
    "show-word-limit": _ctx.showWordLimit,
    modelValue: _ctx.inputModelValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputModelValue) = $event)),
    "show-password": _ctx.type === 'password' ? true : false
  }, null, 8, ["id", "type", "class", "maxlength", "minlength", "clearable", "placeholder", "show-word-limit", "modelValue", "show-password"]))
}