
import {
  ref,
  inject,
  computed,
  onMounted,
  defineComponent,
} from 'vue';
import store from '@/store/index';
import { Actions, Mutations } from '@/store/enums/store.enums';
import BasePre from '@/core/components/base/BasePre.vue';
import InterfaceFilterAdsData from '@/assets/ts/_utils/models/FilterAdsData';
import BaseInputText from '@/core/components/base/BaseInputText.vue';
import BaseSelect from '@/core/components/base/BaseSelect.vue';
import BaseSingleCheckbox from '@/core/components/base/BaseSingleCheckbox.vue';


export default defineComponent({
  name: 'users-filter',
  components: {
    BasePre,
    BaseSelect,
    BaseInputText,
    BaseSingleCheckbox,
  },
  props: {
    pagesTotal: {
      default: 0,
      type: Number,
      required: true,
    },
    itemsTotal: {
      default: 0,
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const filterRubricsArray = ref([]);

    const usersFilterData = inject('usersFilterData') as InterfaceFilterAdsData;

    /**
     * Call the action for the rubrics request.
     */
    const rubricsRequest = async () => {
      // Get the list of rubrics.
      return filterRubricsArray.value = await store.dispatch(
        Actions.GET_RUBRICS_LIST,
        { limit: 10 }
      );
    };

    /**
     * Update the state of the list of rubrics.
     * @param {Array} Array of rubrics.
     */
    const updateStateRubricsList = async data => {
      store.commit(Mutations.SET_RUBRICS_LIST_MUTATION, data);
    };

    /**
     * If we got the flat list,
     * then we need to convert it to the tree.
     * @param {Array} Array of rubrics.
     */
    const convertRubricsListToTree = async data => {
      await store.dispatch(Actions.SET_RUBRICS_TREE, data);
    };

    /**
     * Call the getter for rubrics convertation.
     */
    const rubricsTree = computed(() => {
      return store.getters.getRubricsTree;
    });

    /**
     * Actions on search button click/tap/enter keyup.
     */
    const filterUsersButtonClick = () => {
      context.emit('filterUsersButtonClick', true);
    };

    const resetFilterForm = () => {
      for (const item in usersFilterData) {
        // console.log(item)
        if (item !== 'page' && item !== 'limit') {
          usersFilterData[item] = null;
        }
      }
    };

    /**
     * Detect whether the table has a content.
     * Needed for the table loader.
     * @returns {boolean}.
     */
    const showPagination = computed(() => {
      return props.pagesTotal > 1 ? true : false;
    });

    const paginationClick = val => {
      usersFilterData.page = val;
      context.emit('filterUsersButtonClick');
    };

    /**
     * Parses the window URL for params to apply them to the filter.
     */
    const parseParamsFromUrl = () => {
      const searchString = window.location.search.replace('?', '');
      const urlSearchParams = new URLSearchParams(searchString);
      return Array.from(urlSearchParams);
    };

    /**
     * Set the default value for the usersFilterData
     * when there is no params in the URL.
     * Uses only in the page load stage.
     */
    const setDefaultFilterData = () => {
      usersFilterData.page = 1;
      usersFilterData.limit = 10;
      usersFilterData['filter[status]'] = 'not_verified';
      usersFilterData['filter[active]'] = 'active';
    };

    /**
     * Converts array of params to usersFilterData object.
     * @param {Array} Array of params.
     */
    const convertParamsFromURLArrayToObject = array => {
      array.forEach(([key, value]) => {
        if (key === 'filter[rubric]') {
          const newArray: number[] = value.split(',').map(Number);
          usersFilterData[key] = newArray;
        } else if (key === 'filter[user_id]' || key === 'filter[id]') {
          const newArray: string[] = value.split(',');
          usersFilterData[key] = newArray;
        } else {
          if (value === 'true') {
            usersFilterData[key] = true;
          } else {
            usersFilterData[key] = value;
          }
        }
      });
    };

    /**
     * Mounted hook.
     */
    onMounted(async () => {
      // Get the list of news rubrics.
      if (!store.state.RubricsModule.rubricsTree.length) {
        const rubrucsList = await rubricsRequest();
        if (rubrucsList.length) {
          await updateStateRubricsList(rubrucsList);
          await convertRubricsListToTree(rubrucsList);
        }
      }
      // Parse the URL and convert it to an array.
      const parsedURLArray = parseParamsFromUrl()
      if (parsedURLArray.length) {
        // Fill in the usersFilterData object
        convertParamsFromURLArrayToObject(parsedURLArray);
      } else {
        setDefaultFilterData();
      }
      // Get params on the page reloading.
      context.emit('filterUsersButtonClick');
    });

    return {
      // Pagination
      showPagination,
      paginationClick,
      // Filter
      usersFilterData,
      rubricsTree,
      filterUsersButtonClick,
      parseParamsFromUrl,
      resetFilterForm,
    };
  },
})
