
import {
  ref,
  provide,
  computed,
  reactive,
  onMounted,
  defineComponent,
} from 'vue';
import ApiService from '@/core/services/ApiService';
import isEmptyObject from '@/core/helpers/isEmptyObject';
import UsersFilter from '@/core/components/filters/UsersFilter.vue';
import InterfaceFilterAdsData from '@/assets/ts/_utils/models/FilterAdsData';
import BasePre from '@/core/components/base/BasePre.vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import ConvertDate from '@/core/helpers/convertDate';
import VueEasyLightbox from 'vue-easy-lightbox';
import BasePhoneText from '@/core/components/base/BasePhoneText.vue';

export default defineComponent({
  name: 'AdsList',
  components: {
    BasePre,
    UsersFilter,
    VueEasyLightbox,
    BasePhoneText,
  },
  setup() {
    /**
     * Interface for the single photo.
     */
    interface InterfacePhotoItem {
      id: number
      original_name: string
      path: string
    }

    /**
     * Interface for the source type.
     */
    interface InterfaceSourceType {
      type: string
    }

    /**
     * Interface for the single news object.
     */
    interface InterfaceAdsObject {
      active: boolean
      checked: boolean
      code: string
      country_of_origin: string
      created_at: string
      description: string
      fixed: boolean
      fixed_until: string
      geo_name: string
      id: number
      measure_name: string
      name: string
      owner: {
        id: number
        name: string
        account_pro: boolean
        last_login: string
        last_update: string
        registered_at: string
        stats: {
          ads: {
            active: number
            demand: number
            offer: number
            archive: number
            disabled: number
            rejected: number
            confirmed: number
            spros: number
            offers: number
          }
        }
      }
      photos: Array<{
        id: number
        original_name: string
        path: string
      }>
      previewPhotos: Array<string>
      price: number
      reasons: Array<string>
      rejected_count: number
      rubric: {
        id: number
        name: string
        code: string
      }
      rubric_props: Array<{}>
      show_contacts: boolean
      status: string
      type: string
      updated_at: string
      url: string
      lightboxVisible: boolean
    }

    const rowClasses = 'd-flex flex-column align-items-start justify-content-start'

    /**
     * This function converts requested data for the table.
     * @param {InterfaceAdsObject[]}
     * @returns {InterfaceAdsObject[]} that number, plus one.
     */
    const convertDataForEdit = (
      items: InterfaceAdsObject[]
    ): InterfaceAdsObject[] => {
      return items.map(item => {
        item.reasons = []
        item.checked = false
        item.lightboxVisible = false
        return item
      })
    }

    /**
     * Generate the icon title depends on the status of the news.
     * @returns {string}.
     */
    const generateNewsTitle = status => {
      return status === 'confirmed' ? 'Одобренные' : status === 'rejected' ? 'Отклонённые' : status === 'not_verified' ? 'Не проверенные' : 'Nothing'
    }

    const reasons = ref([])
    const isLoading = ref(false)
    const checkedModel = ref(false)
    const itemsList = ref<InterfaceAdsObject[]>([])
    const totalPages = ref(0)
    const totalItems = ref(0)

    /**
     * Detect whether the table has a content.
     * Needed for the table loader.
     * @returns {Boolean}.
     */
    const tableHasContent = computed(() => {
      return itemsList.value.length > 0 && !isLoading.value
    })

    /**
     * Detect whether one or more checkboxes are checked.
     * Needed for BULK buttons.
     * @returns {Boolean}.
     */
    const someCheckboxesAreChecked = computed(() => {
      return itemsList.value.some(item => item.checked === true)
    })

    /**
     * This function calculate width of table columns depends on the table loading state.
     * If table is loading then we should collapse columns to 0 width.
     * @param {Number} Width of the column.
     */
    const calcWidth = width => {
      return !isLoading.value && !itemsList.value.length ? 0 : width
    }


    const usersFilterData = reactive({
      page: null,
      limit: null,
      'filter[id]': null,
      'filter[name]': null,
      'filter[email]': null,
      'filter[notes]': null,
      'filter[user_type]': null,
      'filter[active]': null,
      'filter[account_pro]': null,
      'filter[empty_phone]': null,
      'filter[email_bounced]': null,
      'filter[email_complained]': null,
      'filter[email_not_check_bounced]': null,
    } as InterfaceFilterAdsData)

    provide('usersFilterData', usersFilterData)

    /**
     * This function make the news array empty.
     */
    const clearTheNewsList = () => {
      itemsList.value = []
    }

    /**
     * This function sets the current page for the pagination.
     * @param {Number} Curent page number.
     */
    const setCurrentPage = val => {
      usersFilterData.page = val
    }

    /**
     * Adds the filter params(converted string) to the window URL.
     * Make a concat of the url's parts.
     * This recreates an URL with new parameters,
     * gotted from the filter(protocol + host + path + params).
     * @param {Object}
     */
    const syncParamsToUrl = params => {
      const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        '?' +
        params
      // This replaces the current URL with the recreated.
      window.history.pushState({ path: newurl }, '', newurl)
    }

    /**
     * Converts filter object to the URL params string.
     * @param {InterfaceFilterAdsData} filter data.
     * @returns {String} concatenated params.
     */
    const convertFilterToParams = (data: InterfaceFilterAdsData) => {
      let paramsAsString = '';
      // Iterate the filter object params.
      for (const [key, value] of Object.entries(data)) {
        if (value && Boolean(value)) {
          if (!paramsAsString.length) {
            // The 1st element in the params row.
            paramsAsString += key + '=' + value;
          } else {
            // The rest elements in the params row.
            paramsAsString += '&' + key + '=' + value;
          }
        }
      }
      // console.log('> convert >> paramsAsString', paramsAsString);
      return paramsAsString;
    }

    /**
     * This function requests reasons.
     * @returns {Array} Array of reasons for reject.
     */
    const requestReasons = async () => {
      const reasonsObject = await ApiService.query(
        '/admin/reference/moder-reasons?type=user',
        {}
      )
        .then(response => {
          return response.data
        })
        .catch(() => {
          console.error('Error to get reasons')
        })

      if (reasonsObject && !isEmptyObject(reasonsObject)) {
        for (const [key, value] of Object.entries(reasonsObject)) {
          reasons.value.push({
            key,
            value,
          })
        }
      } else {
        ElMessage.warning('Что-то не так с запросом причин')
      }
    }


    const sortState = ref({
      id: {
        dir: false,
        work: false,
      },
      email: {
        dir: false,
        work: false,
      },
      'last_name': {
        dir: false,
        work: false,
      },
      'registered_at': {
        dir: false,
        work: false,
      },
      'last_update': {
        dir: false,
        work: false,
      },
      'last_login': {
        dir: false,
        work: false,
      },
    })


    const currentSort = ref(null)

    /**
     * Requests a bunch of news.
     * @param {Object} Fitler data properties.
     */
    const requestItems = () => {
      checkedModel.value = false
      const params:InterfaceFilterAdsData = { ...usersFilterData }

      if (currentSort.value) {
        params.sort = currentSort.value
      }

      /*
      * Convert status ALL to empty value.
      */
      params['filter[status]'] === 'all'
        ? delete params['filter[status]']
        : true
      clearTheNewsList()
      /*
      * Loading bar.
      */
      isLoading.value = true
      ApiService.query('/admin/users', { params })
        .then(response => {
          // console.log('> requestItems >> response.data', response.data)
          /*
          * Set the pagination parameters.
          */
          setCurrentPage(response.data.page)
          totalPages.value = response.data.pages
          totalItems.value = response.data.total
          /*
          * Set items of news item.
          */
          itemsList.value = convertDataForEdit(response.data.items)
          isLoading.value = false
        })
          .catch(() => {
            ElMessage.warning('Что-то не так с запросом объявлений')
            isLoading.value = false
          })
    }


    /**
     * Creating full name from the set of names.
     */
    const convertFullName = (firstName, secondName, lastName) => {
      let fullName = ''
      firstName ? fullName = 'И: ' + firstName : fullName
      secondName ? fullName += '<br>О: ' + secondName : fullName
      lastName ? fullName += '<br>Ф: ' + lastName : fullName
      return fullName;
    };


    const toggleLightbox = (index) => {
      itemsList.value[index].lightboxVisible = true
    }


    const closeLightbox = (index) => {
      itemsList.value[index].lightboxVisible = false
    }

    /**
     * Emitted action from the filter search button.
     * @param {Boolean} Whether to refresh pagination or no.
     */
    const filterUsersButtonClick = pageClear => {
      if (pageClear) {
        usersFilterData.page = 1
      }
      syncParamsToUrl(convertFilterToParams(usersFilterData))
      requestItems()
    }

    /**
     * Detect at least the 1 checked entry.
     */
    const getCheckedEntriesIds = computed(() => {
      const idsArray = []
      itemsList.value.forEach(item => {
        if (item.id && item.checked === true) {
          idsArray.push(item.id)
        }
      })
      return idsArray
    })


    /**
     * Column sorting.
     * After changing the direction making request for news.
     */
    const sortTheColumn = (name) => {
      for (const [key, value] of Object.entries(sortState.value)) {
        key === name ? value.work = true : value.work = false
      }
      sortState.value[name].dir = !sortState.value[name].dir
      currentSort.value = sortState.value[name].dir ? name : `-${name}`

      requestItems()
    }


    const dateConverter = dateString => {
      const dateConverter = new ConvertDate(dateString as string)
      return dateConverter.convertMe()
    }



    /*********************
    * ACTIONS START
    *********************/

    const resetPasswordBulk = () => {
      const params = {
        ids: getCheckedEntriesIds.value
      }

      ElMessageBox.confirm(
        `Создать новые данные для входа и отправить уведомления выбранным пользователям?`,
        'Внимание',
        {
          type: 'warning',
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Отправить',
          dangerouslyUseHTMLString: true,
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              instance.confirmButtonText = 'В процессе...'

              if (getCheckedEntriesIds.value.length) {
                const result = await ApiService.post(`/admin/users/bulk-reset-password`, params)
                  .then(response => {
                    console.log('> response.data', response.data.result)
                    return response.data.result
                  })
                    .catch(() => {
                      return false
                    })
                console.log('> resetPasswordBulk result', result);
                if (result) {
                  ElMessage({
                    type: 'success',
                    message: 'Данные отправлены',
                  })
                } else {
                  ElMessage({
                    type: 'warning',
                    message: 'Данные не отправлены',
                  })
                }
              } else {
                ElMessage({
                  type: 'info',
                  message: 'Вы ничего не выбрали',
                })
              }


              done()
              instance.confirmButtonLoading = false
            } else {
              done()
            }
          },
        }
      )

    }


    const sentAccountConfirmLinkBulk = () => {
      const params = {
        ids: getCheckedEntriesIds.value
      }

      ElMessageBox.confirm(
        `Отправить уведомления с ссылкой для активации выбранным пользователям?`,
        'Внимание',
        {
          type: 'warning',
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Отправить',
          dangerouslyUseHTMLString: true,
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              instance.confirmButtonText = 'В процессе...'

              if (getCheckedEntriesIds.value.length) {
                const result = await ApiService.post(`/admin/users/bulk-account-confirm-link`, params)
                  .then(response => {
                    return response.data.result
                  })
                    .catch(() => {
                      return false
                    })
                console.log('> sentAccountConfirmLinkBulk result', result);
                if (result) {
                  ElMessage({
                    type: 'success',
                    message: 'Уведомления отправлены',
                  })
                } else {
                  ElMessage({
                    type: 'warning',
                    message: 'Уведомления не отправлены',
                  })
                }
              } else {
                ElMessage({
                  type: 'info',
                  message: 'Вы ничего не выбрали',
                })
              }

              done()
              instance.confirmButtonLoading = false
            } else {
              done()
            }
          },
        }
      )

    }


    /**
     * Automatic checkbox selection when selecting the reason checkbox.
     * @param {Number} The news ID
     */
    const checkItemOnReasonSelect = index => {
      const clickedNews = itemsList.value[index]
      if (clickedNews.reasons.length) {
        clickedNews.checked = true
      } else {
        clickedNews.checked = false
      }
    }


    /**
     * Detect reasons of checked entry.
     */
    const getReasonsOfCheckedEntries = computed(() => {
      const entriesObject = {}
      itemsList.value.forEach(item => {
        if (item.id && item.checked === true && item.reasons.length) {
          console.log('> item.reasons', item.reasons)
          entriesObject[item.id] = item.reasons.map(item => item['value']['id'])
        }
      })
      return entriesObject
    })


    const requestToAllowBulkEntries = async (ids) => {
      const params = {
        ids: ids,
      }

      return await ApiService.post('/admin/users/bulk-approve', params)
        .then(response => {
          ElMessage({
            type: 'success',
            message: 'Одобрение подтверждено',
          })
          return response.data.result
        })
          .catch(() => {
            ElMessage({
              type: 'warning',
              message: 'Что-то не так с массовым одобрением пользователей',
            })
            return false
          })
    }

    const allowEntriesBulk = () => {
      ElMessageBox.confirm(
        'Вы действительно хотите одобрить выбранных пользователей?',
        'Внимание',
        {
          type: 'success',
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Одобрить',
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              instance.confirmButtonText = 'В процессе...'
              const result = await requestToAllowBulkEntries(getCheckedEntriesIds.value)
              if (result) {
                instance.confirmButtonLoading = false
                requestItems()
                done()
              }
              done()
            } else {
              ElMessage({
                type: 'info',
                message: 'Одобрение отменено',
              })
              done()
            }
          },
        }
      )
    }


    const requestToRejectEntriesBulk = reasonsObject => {
      const params = {
        reasons: reasonsObject,
      }

      return ApiService.post('/admin/users/bulk-reject', params)
        .then(response => {
          return response.data.result
        })
          .catch(() => {
            ElMessage({
              type: 'warning',
              message: 'Что-то не так с отклонением пользователей',
            })
          })
    }

    const rejectEntriesBulk = () => {
      ElMessageBox.confirm(
        'Вы действительно хотите отклонить выбранные пользователей?',
        'Внимание',
        {
          type: 'warning',
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Отклонить',
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              instance.confirmButtonText = 'В процессе...'
              const result = await requestToRejectEntriesBulk(getReasonsOfCheckedEntries.value)
              if (result) {
                instance.confirmButtonLoading = false
                requestItems()
                ElMessage({
                  type: 'success',
                  message: 'Отклонение пользователей подтверждено',
                })
                done()
              }
            } else {
              ElMessage({
                type: 'info',
                message: 'Отклонение пользователей отменено',
              })
              done()
            }
          },
        }
      )
    }


    const requestToDeleteBulkEntries = ids => {
      const params = {
        ids: ids,
      }

      return ApiService.post('/admin/users/bulk-destroy', params)
        .then(response => {
          return response.data.result
        })
        .catch(() => {
          ElMessage({
            type: 'warning',
            message: 'Что-то не так с массовым удалением пользователей',
          })
        })
    }

    const deleteEntriesBulk = () => {
      ElMessageBox.confirm(
        'Вы действительно хотите удалить выбранных пользователей?',
        'Внимание',
        {
          type: 'warning',
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Удалить',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              instance.confirmButtonText = 'В процессе...'
              const result = requestToDeleteBulkEntries(getCheckedEntriesIds.value)
              if (result) {
                instance.confirmButtonLoading = false
                requestItems()
                done()
              }
              ElMessage({
                type: 'success',
                message: 'Удаление пользователей подтверждено',
              })
              done()
            } else {
              ElMessage({
                type: 'info',
                message: 'Удаление пользователей отменено',
              })
              done()
            }
          },
        }
      )
    }


    const switchAllCheckboxes = () => {
      if (checkedModel.value) {
        itemsList.value.forEach(item => {
          item.checked = false
        })
      } else {
        itemsList.value.forEach(item => {
          item.checked = true
        })
      }
    }


    const requestToAllowEntry = item => {
      return ApiService.post(`/admin/users/${item.id}/approve`, { id: item.id })
        .then(response => {
          return response.data.result
        })
          .catch(() => {
            ElMessage({
              type: 'warning',
              message: 'Что-то не так с одобрением пользователя',
            })
          })
    }

    const allowEntry = item => {
      const entry = item
      ElMessageBox.confirm(
        `Вы действительно хотите одобрить этого пользователя: <strong>${entry.name}</strong>?`,
        'Внимание',
        {
          type: 'success',
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Одобрить',
          dangerouslyUseHTMLString: true,
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              const resultOfAllowEntry = await requestToAllowEntry(entry)
              instance.confirmButtonLoading = true
              instance.confirmButtonText = 'В процессе...'
              if (resultOfAllowEntry) {
                requestItems()
                instance.confirmButtonLoading = false
                ElMessage({
                  type: 'success',
                  message: 'Одобрение пользователя подтверждено',
                })
                done()
              }
            } else {
              ElMessage({
                type: 'warning',
                message: 'Одобрение пользователя отменено',
              })
              done()
            }
          },
        }
      )
    }


    const requestToRejectEntry = item => {
      const params = {
        reasons: item.reasons.map(item => item.value.id),
      }

      return ApiService.post(`/admin/users/${item.id}/reject`, params)
        .then(response => {
          return response.data.result
        })
          .catch(() => {
            ElMessage({
              type: 'warning',
              message: 'Что-то не так с одобрением пользователя',
            })
          })
    }

    const rejectEntry = item => {
      const entry = item
      ElMessageBox.confirm(
        `Вы действительно хотите отклонить этого пользователя: <strong>${entry.name}</strong>?`,
        'Внимание',
        {
          type: 'success',
          cancelButtonText: 'Отмена',
          confirmButtonText: 'Отклонить',
          dangerouslyUseHTMLString: true,
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              const resultOfAllowEntry = await requestToRejectEntry(entry)
              instance.confirmButtonLoading = true
              instance.confirmButtonText = 'В процессе...'
              if (resultOfAllowEntry) {
                requestItems()
                instance.confirmButtonLoading = false
                ElMessage({
                  type: 'success',
                  message: 'Отклонение пользователя подтверждено',
                })
                done()
              }
            } else {
              ElMessage({
                type: 'warning',
                message: 'Отклонение пользователя отменено',
              })
              done()
            }
          },
        }
      )
    }

    /*********************
    * ACTIONS END
    *********************/

    /**
     * Finding both: checked items and unchecked reasons for an entry.
     * If at least one of checked items has no reason,
     * then we shouldn't allow the Bulk Declining
     * and should return FALSE
     * Should return TRUE only if all the entries has the selected reason.
     * 'Value 1' - if the item are checked, and the reason are checked.
     * 'Value 2' - if the item are checked, but without the reason.
     * 'Value 3' - if the item are NOT checked, but the reason are checked.
     * We should return FALSE if any of items matched the 'Value 2'.
     * @returns {boolean}.
     */
    const detectUncheckedReasonsOnCheckedEntries = computed(() => {
      const entries = itemsList.value.map(item => {
        if (item.checked && item.reasons.length) {
          return 1
        } else if (item.checked && !item.reasons.length) {
          return 2
        } else if (!item.checked && item.reasons.length) {
          return 3
        }
      })
      const result = entries.some(item => item === 2)
      return result
    })


    /**
     * Title for the reject button.
     * @returns {String}.
     */
    const rejectButtonTitle = computed(() => {
      let title = ''
      if (
        !someCheckboxesAreChecked.value &&
        !detectUncheckedReasonsOnCheckedEntries.value
      ) {
        title = 'Выберите хотя бы одного пользователя из списка'
      } else if (
        someCheckboxesAreChecked.value &&
        detectUncheckedReasonsOnCheckedEntries.value
      ) {
        title = 'Выберите причину отклонения для выбранных пользователей'
      } else {
        title = 'Отклонить пользователей'
      }
      return title
    })


    /**
     * Detect the DISABLE status of the Bulk Reject button.
     * We should show the Bulk Reject button if:
     * 1. At least 1 entry are checked;
     * 2. Detect entries without the reason (have to be sure that every checked entry has a reason)
     * @returns {Boolean}.
     */
    const bulkRejectStatus = computed(() => {
      return !someCheckboxesAreChecked.value
        || detectUncheckedReasonsOnCheckedEntries.value
    })

    const nameColumnWidth = 270;


    /**
     * Detects the max width for the email column.
     * We detects the longest email in array and multiply it by 10.
     * @returns {Number}.
     */
    const maxEmailColumnWidth = computed(() => {
      const width = Math.max(...itemsList.value.map(o => o['email'].length)) * 10;
      console.log('> width', width);
      return width;
    });


    /**
     * Mounted hook.
     */
    onMounted(() => {
      requestReasons();
    });

    return {
      nameColumnWidth,
      maxEmailColumnWidth,
      // PAGINATION
      setCurrentPage,
      // NEWS
      itemsList,
      requestItems,
      clearTheNewsList,
      convertDataForEdit,
      // FILTER
      usersFilterData,
      filterUsersButtonClick,
      // REASONS
      reasons,
      requestReasons,
      dateConverter,
      // SORTING
      sortState,
      sortTheColumn,
      currentSort,
      convertFullName,
      closeLightbox,
      toggleLightbox,
      // ACTIONS
      allowEntry,
      rejectEntry,
      allowEntriesBulk,
      rejectEntriesBulk,
      deleteEntriesBulk,
      resetPasswordBulk,
      sentAccountConfirmLinkBulk,
      // MODERATION
      checkItemOnReasonSelect,
      someCheckboxesAreChecked,
      // CHECKBOXES
      switchAllCheckboxes,
      // COMMON
      checkedModel,
      tableHasContent,
      isLoading,
      totalPages,
      totalItems,
      isEmptyObject,
      getCheckedEntriesIds,
      rowClasses,
      calcWidth,
      generateNewsTitle,
      bulkRejectStatus,
      rejectButtonTitle,
    }
  },
})
