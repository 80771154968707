
import {
  computed,
  defineComponent,
} from 'vue'

export default defineComponent({
  name: 'base-single-checkbox',
  props: {
    modelValue: {
      type: Boolean,
    },
    id: {
      type: String,
      default: 'id-default',
      required: true,
    },
    size: {
      type: String,
      default: 'large',
      required: false,
    },
    label: {
      type: String,
      default: 'Лейбл чекбокса',
      required: false,
    },
  },
  setup(props, context) {
    const inputModelValue = computed({
      get() {
        if (props.modelValue === null) {
          return false;
        }
        return props.modelValue
      },
      set(value) {
        return context.emit('update:modelValue', value)
      }
    })

    return {
      inputModelValue,
    }
  },
})
